<template>
  <div>
    <div class="huge">404</div>
    <div class="large">Not found</div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
div {
  line-height: 100%;
}
.huge {
  font-size: 120px;
}
.large {
  font-size: 40px;
}
</style>
